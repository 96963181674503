import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Careers() {
  return (
    <section>
      <Navbar/>
      <div className='content-container flex flex-col text-center w-full mb-20  mx-auto'>
      <h1 className='py-10 sm:text-3xl text-2xl font-bold title-font mb-4 bg-gray-900 text-white'>Careers</h1>
      <p className='text-justify px-10'>Working at Chandrabhaga Roadlines Pvt. Ltd. means continually growing and challenging yourself. As a global business with diverse operations, we offer employees rewarding career opportunities that help you grow with the company. Be part of our success as a leading global logistics provider and find out how you can contribute to our business.</p>
      <div className='py-20'>Coming Soon....</div>
      </div>
      
      <footer className='bottom-0 relative footer--pin'><Footer/></footer>
    </section>
  )
}

export default Careers