import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'


function ContactUs() {

  return (
    <section>
      <div>
        <Navbar />
      </div>
      <div className='content-container flex flex-col text-center w-full mb-20  mx-auto overflow-hidden'>
        <h1 className='py-10 sm:text-3xl text-2xl font-bold title-font mb-4 bg-gray-900 text-white'>Contact Us</h1>
        <div className='py-10 px-5 sm:px-10'>
          <iframe style={{ width: "100%", height: "600px" }} id="gmap_canvas" src="https://maps.google.com/maps?q=Sci%20Fi%20Technology%20Systems%20Pvt.%20Ltd.%20Niranjan%20Corner,%20Shop%20No.2,%20Niranjan%20Corner,%20Hotel%20Samrat%20Road,%20Near%20Nobel%20Furniture%20Mall,%20Bhigwan%20Road,%20MIDC%20Baramati%20Road,%20Saily%20Nagar,%20Baramati,%20Maharashtra%20413102&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title='map'></iframe>
          <p className='text-justify sm:p-10 p-5'>Address: Our office is beside the Sci Fi Technology Systems Pvt. Ltd. Niranjan Corner, Shop No.2, Niranjan Corner, Hotel Samrat Road, Near Nobel Furniture Mall, Bhigwan Road, MIDC Baramati Road, Saily Nagar, Baramati, Maharashtra 413102</p>
        </div>
        <div className='text-bold text-3xl'>Our Branches</div>
        <section class="text-orange-400 body-font overflow-hidden">
          <div class="container px-5 py-24 mx-auto">
            <div class="-my-8 divide-y-2 divide-orange-400">
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5">Baramati<br/><h3>(Main Branch)</h3></span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 8805434343 | +91 9527000043</h2>
                  <p class="leading-relaxed">Chandrabhaga Roadlines, Plot No 17, Niranjan Corner, Shop No 3, Near Nobel Furniture Mall, Bhigwan Road, Baramati-413102.</p><br/>
                  <Link href="" className="text-orange-400 ml-1" onClick={()=>window.location = 'mailto:Kuldeep@cbrgroup.in'} target="_blank">Kuldeep: Kuldeep@cbrgroup.in</Link>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5">Delhi</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145744343</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5"> Ahmedabad</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145764343</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5">Gwalior</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145774343</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5">Mumbai</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145574343</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5"> Pune</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145244343</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5">Bangalore</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145784343</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
              <div class="py-8 flex flex-wrap md:flex-nowrap">
                <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                  <span class="font-semibold title-font text-orange-400 px-5">Chennai</span>
                </div>
                <div class="md:flex-grow">
                  <h2 class="text-2xl font-medium text-orange-400 title-font mb-2">+91 9145794343 | +91 9545892807</h2>
                  <p class="leading-relaxed"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer className='bottom-0 relative footer--pin'><Footer /></footer>
    </section>
  )
}

export default ContactUs