import React, { useState } from 'react'
import Countup from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'


function Counter() {
  const [viewPortEntered, setViewPortEntered]=useState(false)
  return (
    <section className='counter py-7 px-4 overflow-hidden bg-black text-white'>
        <div className='counter-row  grid  grid-cols-2 sm:grid-cols-4 py-5 px-4 justify-content-center'>

          <div className='counter-column py-3 gap-4 px-20'>
            <strong className='text-3xl' data-number='10'>
              <Countup start= {viewPortEntered ? null : 0} end={10}>
                {({countUpRef})=>{
                  return(
                    <VisibilitySensor 
                    active={!viewPortEntered} 
                    onChange={isVisible =>{
                      if(isVisible){
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                    >
                    <span className='number' ref={countUpRef}/>
                    </VisibilitySensor>
                  )
                }}
              </Countup>
            </strong><br/>
            <span>Years Of Experience</span>
          </div>

          <div className='counter-column py-3  gap-4 px-20'>
            <strong className='text-3xl' data-number='8'>
              <Countup start= {viewPortEntered ? null : 0} end={8}>
                {({countUpRef})=>{
                  return(
                    <VisibilitySensor 
                    active={!viewPortEntered} 
                    onChange={isVisible =>{
                      if(isVisible){
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                    >
                    <span className='number' ref={countUpRef}/>
                    </VisibilitySensor>
                  )
                }}
              </Countup>
            </strong><br/>
            <span>Branches in India</span>
          </div>

          <div className='counter-column py-3  gap-4 px-20'>
            <strong className='text-3xl' data-number='1500'>
              <Countup start= {viewPortEntered ? null : 0} end={1500}>
                {({countUpRef})=>{
                  return(
                    <VisibilitySensor 
                    active={!viewPortEntered} 
                    onChange={isVisible =>{
                      if(isVisible){
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                    >
                    <span className='number' ref={countUpRef}/>
                    </VisibilitySensor>
                  )
                }}
              </Countup>
            </strong><br/>
            <span>Size of our Fleet</span>
          </div>

          <div className='counter-column py-3  gap-4 px-20'>
            <strong className='text-3xl' data-number='27000'>
              <Countup start= {viewPortEntered ? null : 0} end={27000}>
                {({countUpRef})=>{
                  return(
                    <VisibilitySensor 
                    active={!viewPortEntered} 
                    onChange={isVisible =>{
                      if(isVisible){
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                    >
                    <span className='number' ref={countUpRef}/>
                    </VisibilitySensor>
                  )
                }}
              </Countup>
            </strong><br/>
            <span>Total Kms Driven</span>
          </div>
        </div>
    </section>
  )
}

export default Counter