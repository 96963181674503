import React, {useEffect}from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';


function Companies() {
        
    useEffect(()=>{
          Aos.init({duration:2000});
      })
  return (
    <section>
        <div className='Text text-bold text-center text-5xl pt-10 overflow-hidden'  data-aos="fade-down">Our Partners<br/></div>
        <p className='text-2xl text-gray-500 text-center pt-5' data-aos="fade-down">These are the companies we are working with</p>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg">
        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="Ball.webp"
              alt="Company"
            />
          </div>

        </div>
        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="Barry.webp"
              alt="company"
            />
          </div>
    
        </div>
        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="Britannia.webp"
              alt="company"
            />
          </div>
          
        </div>
        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="Ferrero1.webp"
              alt="company"
            />
          </div>
        </div>

        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="https://www.schreiberdynamixdairy.com/assets/img/logo1-blue.png"
              alt="company"
            />
          </div>
        </div>

        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="http://www.lgnewsroom.com/wp-content/uploads/2017/07/LG-Logo20170727132503101.jpg"
              alt="company"
            />
          </div>
        </div>

        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="https://www.globaldairytrade.info/assets/Uploads/sellers/logos/_resampled/ResizedImage360270-logo-Amul-170px.png"
              alt="company"
            />
          </div>
        </div>

        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="https://cdn.bauligroup.com/assets/cms/logo-bauli.svg"
              alt="company"
            />
          </div>
        </div>

        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="https://1.bp.blogspot.com/-dr0gyMzRTqI/XMx6i_Dr-GI/AAAAAAAADHg/sRUgpjBsDkYCDH_zbct4Zzyp0ynVyY5BgCK4BGAYYCw/s1600/FCI-Recruitment-2017.jpg"
              alt="company"
            />
          </div>
        </div>

        <div>
          <div className="relative pb-56 mb-4 rounded shadow-md lg:pb-64" data-aos="fade-down">
            <img
              className="absolute object-contain w-full h-full rounded"
              src="cropped-OY-INDIA-LOGO-min.png"
              alt="company"
            />
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}

export default Companies