import './App.css';
import Companies from './components/Companies';
import Contact from './components/Contact';
import Counter from './components/Counter';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Presentation from './components/Presentation';
import Sectors from './components/Sectors';
import Services from './components/Services';



function App() {
  return (
    <>
    <Navbar/>
    <Hero/>
    <Presentation/>
    <Sectors/>
    <Services/>
    <Counter/>
    <Companies/>
    <Contact/>
    <Footer/>
    </>
  );
}

export default App;
