import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function ServicesPage() {
  return (
    <section>
      <Navbar/>
      <h1 className='py-10 sm:text-3xl text-3xl font-bold title-font text-center text-white bg-gray-900'>Services</h1>
    <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl ">
      <div className="max-w-xl mb-6 sm:mx-auto sm:text-center md:mb-10 lg:max-w-2xl">
        <p className="text-base text-gray-700 md:text-lg">
        Service that matters, Service truly Different, Service what you deserves.
        </p>
      </div>
      <div className="grid gap-6 row-gap-5 lg:grid-cols-3">
        <div>
          <img
            className="object-cover w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
            src="DSC01500.jpg"
            alt=""
          />
          <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
           Transportation
          </h5>
          <p className="text-gray-700">
          Transportation is the movement of goods and logistics is the management of the inward and outward transportation of goods from the manufacturer to the end user.Efficient transportation is important to the survival of a transportation and distribution service. Efficient transportation should be analyzed for the best delivery route, as well as cost-effective packaging that ensures low investment and safety of goods.
          Our transportation services are the best. We have the skillfull drivers and also our trucks are at best condition for the transportation of your goods.  
          </p>
        </div>
        <div>
          <img
            className="object-fit w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
            src="DJI_0037.JPG"
            alt=""
          />
          <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Warehousing
          </h5>
          <p className="text-gray-700 text-justify">
          A warehouse is a building for storing goods.Warehouses are used by manufacturers, importers, exporters, wholesalers, transport businesses, customs, etc.
          They are usually large plain buildings in industrial parks on the outskirts of cities, towns, or villages. We provide the best storage for your goods and we also have the different size of cold storages. Which will help you to keep your goods safe and hygenic for long period of time.
          Your goods are in right hand. 
          </p>
        </div>
        <div>
          <img
            className="object-cover w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
            src="loading1.jpg"
            alt=""
          />
          <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Loading & Unloading
          </h5>
          <p className="text-gray-700">
           Loading and unloading cargo are activities which heighten the chance of workplace injuries. Some of the most common workplace injuries related to vehicle loading and unloading include: Sprains and strains from improper lifting or handling of cargo ,Crushing injuries caused by colliding with a forklift, truck, falls from loading docks, or falling stacked materials ,Bruises and cuts etc.
           But no worries we have different machineries and skilled workers to hande the parcels with safety.We also have the team who ensures that loading and unloading of your goods is proper.
          </p>
        </div>
      </div>
    </div>

      <div>
      <Footer/>
      </div>
    </section>
  );
};

export default ServicesPage