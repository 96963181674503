import React, {useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';

function Services() {
    useEffect(()=>{
      Aos.init({duration:2000});
  })
  return (
    <section className='pb-20 overflow-x-hidden text-gray-600 body-font'>
        <div className=' Text text-bold text-center text-5xl pt-20'  data-aos="fade-down">Our Services<br/></div>
            <p className='text-2xl text-gray-500 text-center pt-5' data-aos="fade-down">Service that matters, Service truly Different, Service what you deserves.</p>
        <br/>
        <div className='text-justify text-gray-400 pb-15 px-10'  data-aos="fade-down">
            <p>As an organization, it is important for your business to have a strong and operational supply chain. This means that every step of your product's journey should be planned and executed flawlessly in order to ensure high-quality products are deliveorange on time and within budget. With the right services and infrastructure in place, your supply chain can help alleviate some of the logistical challenges faced by businesses of all sizes.</p>
        </div><br/><br/>
          <div> 
            <h1 className='font-bold px-6 py-10 text-center text-5xl'>Transportation</h1>
          </div>
          <div className="container px-5 pb-24 mx-auto flex flex-wrap" >
            <div className="lg:w-1/2 w-full lg:mb-0 rounded-lg overflow-hidden">
              <img alt="feature" className="object-cover object-center h-full w-full"  data-aos="zoom-in" src="Truck5.jpg"/>
            </div>
            <div className="flex flex-col flex-wrap lg:py-6 -mb-0 lg:w-1/2 lg:pl-12 lg:text-left text-center bg-gray-900">
              <div className="flex flex-col mb-10 lg:items-start items-center pt-10"  data-aos="zoom-in-up">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-200 text-orange-400 mb-5" >
                  <img src='Truck_icon.png' alt='' className='w-10 h-10 '/>
                </div>
              <div className="flex-grow" >
                <h2 className="text-white text-lg title-font font-medium mb-3" >Excellent Trucking</h2>
                <p className="leading-relaxed text-base text-orange-400">Because you need a logistics partner to keep up with your growth.</p>
              </div>
            </div>
            <div className="flex flex-col mb-10 lg:items-start items-center"  data-aos="zoom-in-up">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-200 text-orange-400 mb-5">
                <img src='Safety_icon.png' alt='' className='w-10 h-10'/>
              </div>
            <div className="flex-grow">
              <h2 className="text-white text-lg title-font font-medium mb-3">Efficient & Safety</h2>
              <p className="leading-relaxed text-base text-orange-400">Efficiency on the move & Goods safety is our key feature.</p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center"  data-aos="zoom-in-up">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-200 text-orange-400 mb-5">
              <img src='Professional_icon.png' alt='' className='w-10 h-10'/>
            </div>
          <div className="flex-grow">
            <h2 className="text-white text-lg title-font font-medium mb-3">Professional</h2>
            <p className="leading-relaxed text-base text-orange-400">We are a team of professionals with 100% commitment.</p>
            </div>
          </div>
        </div>
      </div>
  

  {/* second */}
          <div> 
            <h1 className='font-bold px-6 py-10 text-center text-5xl'>Warehousing</h1>
          </div>
  <div className="container px-5 pb-24 mx-auto flex flex-wrap" >
    <div className="lg:w-1/2 w-full  lg:mb-0 rounded-lg overflow-hidden">
      <img alt="feature" className="object-cover object-center h-full w-full"  data-aos="zoom-in" src="DSC01434.JPG"/>
    </div>
    <div className="flex flex-col flex-wrap lg:py-6 -mb-0 lg:w-1/2 lg:pl-12 lg:text-left text-center  bg-gray-900">
      <div className="flex flex-col lg:items-start items-center pt-10" data-aos="zoom-in-up">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-100 text-orange-400 mb-5">
        <img src='Warehouse_icon.png' alt='' className='w-9 h-9'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-white text-lg title-font font-medium mb-3">Extravagent Warehouses</h2>
          <p className=" pb-10 leading-relaxed text-base text-orange-400">EXPECT MORE. Where every one satisfied</p>
        </div>
      </div>
      <div className="flex flex-col mb-10 lg:items-start items-center"data-aos="zoom-in-up">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-100 text-orange-400 mb-5" >
          <img src='Hygenic_icon.png' alt='' className='w-10 h-10'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-white text-lg title-font font-medium mb-3">Hygenic</h2>
          <p className="leading-relaxed text-base text-orange-400">Because you deserve good. For us, Quality matters not Quantity</p>
        </div>
      </div>
      <div className="flex flex-col mb-10 lg:items-start items-center" data-aos="zoom-in-up">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-100 text-orange-400 mb-5" >
           <img src='support_icon.png' alt=''className='w-10 h-10'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-white text-lg title-font font-medium mb-3">Excellent Service</h2>
          <p className="leading-relaxed text-base text-orange-400">Where every one satisfies and a place with best quality and service.</p>
        </div>
      </div>
    </div>
  </div>

{/* third */}

          <div> 
            <h1 className='font-bold px-6 py-10 text-center text-5xl'>Loading & Unloading</h1>
          </div>

  <div className="container px-5 pb-24 mx-auto flex flex-wrap">
    <div className="lg:w-1/2 w-full lg:mb-0 rounded-lg overflow-hidden">
      <img alt="feature" className="object-cover object-center h-full w-full" src="loading1.jpg" data-aos="zoom-in"/>
    </div>
    <div className="flex flex-col flex-wrap lg:py-6 -mb-0 lg:w-1/2 lg:pl-12 lg:text-left text-center  bg-gray-900">
      <div className="flex flex-col lg:items-start items-center pt-10" data-aos="zoom-in-up">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-100 text-orange-400 mb-5">
          <img src='time-management_icon.png' alt='' className='w-10 h-10'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-white text-lg title-font font-medium mb-3">Quick Service</h2>
          <p className="pb-10 leading-relaxed text-base text-orange-400">Time is money. We save you both. Shipments delivered on time with no hassle.</p>
        </div>
      </div>
      <div className="flex flex-col mb-10 lg:items-start items-center" data-aos="zoom-in-up">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-100 text-orange-400 mb-5">
          <img src='save-money_icon.png' alt='' className='w-10 h-10'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-white text-lg title-font font-medium mb-3">Money Saving</h2>
          <p className="leading-relaxed text-base text-orange-400">Let us unload your worries and load you up with a truckload of savings!</p>
        </div>
      </div>
      <div className="flex flex-col mb-10 lg:items-start items-center"data-aos="zoom-in-up">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-orange-100 text-orange-400 mb-5">
         <img src='assurance_icon.png' alt='' className='w-10 h-10'/>
        </div>
        <div className="flex-grow">
          <h2 className="text-white text-lg title-font font-medium mb-3">Tailored to your needs</h2>
          <p className="leading-relaxed text-base text-orange-400">We load, you go.You never have to worry about lost, stolen, or damaged freight.</p>
        </div>
      </div>
    </div>
  </div>
</section>
   
  )
}

export default Services