import React from 'react'

function Hero() {

    return (
        <section>
            <div className='carousel max-w-[1600px] h-[780px] w-full m-auto relative z-[-1]'>
                <div className='w-full h-full bg-center object-cover brightness-75'>
                    <video src='DJI_0038.MP4' autoPlay loop muted ></video>
                </div>
                {/* <div className=' main-text text-5xl font-bold absolute flex flex-column justify-center top-0  text-orange-500 overflow-hidden pb-10  '>CHANDRABHAGA ROADLINES</div><br /> */}
                {/* <div className=' main-text1 text-2xl font-semibold absolute flex flex-column justify-center top-0 text-white overflow-hidden'>Logistics that is connecting you to endless possibilities</div> */}
                <div>
                    <h1  className=' main-text  text-2xl sm:text-5xl  font-bold absolute flex flex-column justify-center top-0  text-orange-400 overflow-hidden sm:pb-5 pb-5 '>CHANDRABHAGA ROADLINES</h1>
                    <span className=' main-text1 text-base sm:text-lg font-semibold absolute flex flex-column justify-center top-0 text-white overflow-hidden p-2 sm:p-1' >Logistics that is connecting you to endless possibilities</span>
                </div>
                
            </div>
        </section>
    )
}

export default Hero;