import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';

    export default function Presentation() {
useEffect(()=>{
    Aos.init({duration:2000});
})

        const posts = [
            {
                title: "Transportation",
                img: "Trucks2.jpg",
                content: "Are you looking for a reliable and cost-effective way to transport your goods? Look no further! Our goods transportation service is here to help. We provide safe and secure transportation of your products from one location to another, with our fleet of modern vehicles. Our experienced drivers are trained to handle any kind of goods, from fragile items to heavy machinery. We ensure for and timely delivery, of your goods will reach their destination safely and on time."
            },
            {
                title: "Warehousing",
                img: "DJI_0029.JPG",
                content: "Are you looking for a reliable and cost-effective way to store your food and other perishables? Look no further! Our cold storage solution is the perfect choice for businesses of all sizes. Our state-of-the-art technology ensures that your items are kept at the optimal temperature, while our advanced security system ensures that your items are safe and secure. With our cold storage solution, you can rest assured that your perishables will remain fresh and safe for longer."
            },
            {
                title: "Loading & Unloading",
                img: "Loading.jpg",
                content: "Moving can be a stressful and time-consuming process. Our loading and unloading services make it easier for people to move their belongings quickly and safely. We provide experienced professionals who are trained to handle all types of items, from furniture to fragile items. Our services are available 24/7, so you can rest assured that your move will be handled with care and efficiency. With our competitive rates and reliable service, we make moving a breeze!"
            },
        ];
        return (
            <>
            <section className='pb-20'>
            <div className=' Text text-bold text-center text-5xl pt-20'  data-aos="fade-down" >What We Present</div><br/>
              <p className='text-2xl text-gray-500 text-center pb-20' data-aos="fade-down">Build a Safety Culture for Sustainable Supply Chain.</p>
                <div className="grid gap-10 lg:grid-cols-3 px-10 rounded-lg ">
                    {posts.map((items, key) => (
                        <div className='w-full rounded-lg shadow-md hover:scale-110 transition duration-300 ease-in-out 'data-aos="zoom-in-up" key={key}>
                            <img
                                className="object-cover w-full h-48 "
                                src={items.img}
                                alt=""
                            />
                            <div className="p-4">
                                <h4 className="text-xl font-semibold text-black-600">
                                    
                                    {items.title}
                                </h4>
                                <p className="mb-2 leading-normal text-gray-500">
                                {items.content}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            </>
        );
    }