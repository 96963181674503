import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Gallery() {
  return (
    <section>
      <Navbar />
      <section className="text-gray-600 body-font">
        <h1 className=" py-10 sm:text-3xl text-2xl font-bold title-font mb-4 bg-gray-900 text-white text-center">Gallery</h1>
        <div className="px-10 pb-10 container mx-auto overflow-hidden">
          <div className="flex flex-col text-center w-full mb-20">

          </div>
          <div className="flex flex-wrap -m-4">
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center z-[-1]" src="DJI_0034.JPG" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className="w-full h-full object-cover object-center z-[-1]" src=" IMG-20220422-WA0050.jpg" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className="w-full h-full object-cover object-center" src="Img1.jpg" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className="w-full h-full object-cover object-center" src="DSC01423.JPG" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center" src="DSC01428.JPG" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center" src="DSC01432.JPG" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center" src=" Loading.jpg" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center" src="IMG-20220422-WA0055.jpg" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center" src="IMG-20220422-WA0054.jpg" />
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img alt="gallery" className=" w-full h-full object-cover object-center" src=" DSC01433.JPG" />
              </div>
            </div> 
          </div>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </section>
  )
}

export default Gallery