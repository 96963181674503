import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function About() {
  return (
    <section>
      <Navbar />
      <div>
        <h1 className='py-10 sm:text-3xl text-3xl font-bold title-font text-center text-white bg-gray-900'>About Us </h1>
        <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-5 md:mb-0">
            <img class="object-cover object-center rounded" alt="hero" src="Founder.jpg" />
          </div>
          <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 class="font-bold sm:text-4xl text-3xl mb-4  text-gray-900">Amol Kate</h1>
            <p class="mb-8 leading-relaxed text-justify">Mr.Amol Kate the Founder of Chandrabhaga Roadlines has established his firm on 15/07/2013 Chandrabhaga Roadlines is the proprietary firm established by Mr.Amol Kate for cater the fleet services to major of the above said companies in Baramati area. Mr Amol Kate 5 years of MNC companies experience.He and his brother Mr.Atul Kate have an experience of this field since last 10 years.He caters for Food Corporation of India for transportation of food grains.</p>
          </div>
        </div>
        <div>
          <h1 className=' px-20 font-bold sm:text-2xl text-2xl mb-4  text-gray-900 '> Our Strengths</h1>
        </div>
        <div className='sm:px-20 sm:pb-20 px-5 py-5leading-relaxed text-justify'>
          <li>Strong and reliable road services</li>
          <li>Partnership/Communication with providers</li>
          <li>Skilled workforce</li>
          <li>Strong/ reliable multimodal connectivity</li>
          <li>Other family members are engaged in cold storage and warehouse business since last 15 years and which are in tailor made warehouses cold storages and having a setup for store various types of farm produce, food grains, Milk and Milk Products, fresh fruits, Pulps, grapes and Bedana.</li>
          <br />
          <h1 className='font-bold sm:text-2xl text-2xl mb-4  text-gray-900'>Our Vision</h1>
          <p className='text-justify'>Baramati is a fast-developing cities due to its location and politically influential leadership. The industries are setting up their units in Baramati and there is full fledged MIDC. There is large scope of industrial sheds and godowns as well as cold storages to be rented out to new entrants. There is also potential for agricultural godowns cold storages as it is agricultural area. Due to avaibility of raw material like wheat Paddy Grapes, tomatoes, Strawberries and other fresh farm harvest there are many MNC's attracted to Baramati, Phaltan and having processing, manufacturing units in Baramati and Phaltan.  </p>
        </div>
        <Footer />
      </div>
    </section>
  )
}

export default About