import React,{useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';

function Sectors() {
  useEffect(()=>{
    Aos.init({duration:2000});
})

  return (
    <section  className='pb-20 bg-gray-100'>
        <div className='Text text-bold text-center text-5xl pt-10' data-aos="fade-down">Sectors we Support</div><br/>
        <p className='text-2xl text-gray-500 text-center pb-10'data-aos="fade-down">These are the different types of sectors we work on.</p>

        <div className="flex flex-col bg-gray-100 m-auto p-auto">
          <div className="flex overflow-x-scroll pb-10 overflow-x-auto">
            <div className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10 ">
              <div className="inline-block px-3">
                <div className=" container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out " data-aos="flip-up" data-aos-delay="500">
                  <img src='food_nutritional.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Food Products</div>
                    </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className=" container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" data-aos="flip-up" data-aos-delay="600">
                  <img src='pharma.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className=' overlaytext text-xl'>Pharma Products</div>
                    </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className="container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease" data-aos="flip-up" data-aos-delay="700">
                  <img src='Electronics.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Electronics and Electricals</div>
                    </div>
                  </div>
              </div>
              <div className="inline-block px-3">
                <div className=" container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" data-aos="flip-up" data-aos-delay="800">
                  <img src='Telecom.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Telecommunication</div>
                    </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className=" container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" data-aos="flip-up"data-aos-delay="900">
                  <img src='Construction.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Construction</div>
                    </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className="container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" data-aos="flip-up"data-aos-delay="1000">
                <img src='Automobile.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Automobile</div>
                    </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className="container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" data-aos="flip-up" data-aos-delay="1100">
                <img src='Mining.jpg' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Mining and Minerals</div>
                    </div>
                </div>
              </div>
              <div className="inline-block px-3">
                <div className="container relative w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" data-aos="flip-up" data-aos-delay="1200">
                <img src='Iron steel.png' alt='' className='image'></img>
                    <div className='middle'>
                      <div className='overlaytext text-xl'>Iron & Steel</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Sectors