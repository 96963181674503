import React, { useEffect, useRef } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import emailjs from '@emailjs/browser'



function Contact() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })

  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8vl4p1l', 'template_9zydlyw', e.target, '724FN5C-H-yNXE78g')
      .then((result) => {
        console.log(result.text);
        alert("message sent successfully")
      }, (error) => {
        console.log(error.text);
        alert('error sending message');
      });
      e.target.reset();
  }
  return (
    <section className=" contact body-font relative">
      <div className="container px-5 py-20 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-5xl text-5xl font-bold title-font mb-4 Text" data-aos="fade-down">Contact Us</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed  text-2xl text-gray-500" data-aos="fade-down">We are here for you</p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          
            <form ref={form} onSubmit={sendEmail}>
              <label className='text-orange-400 rounded-lg' >Name</label>
              <input type="text" name="user_name"/>
              <label className='text-orange-400 rounded-lg'>Email</label>
              <input type="email" name="user_email" />
              <label className='text-orange-400 rounded-lg'>Message</label>
              <textarea name="message" />
              <input type="submit" value="Send"className='bg-orange-400 rounded-lg cursor-pointer hover:bg-orange-500 text-white text-bold text-center' />
            </form>
          
        </div>
      </div>
    </section >
  )
}

export default Contact
