import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import ServicesPage from './pages/ServicesPage';
import Gallery from './pages/Gallery';
import Careers from './pages/Careers';
import ContactUs from './pages/ContactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path='/' element={<App/>}> </Route>
      <Route path='About' element={<About/>}></Route>
      <Route path='ServicesPage' element={<ServicesPage/>}></Route>
      <Route path='Gallery' element={<Gallery/>}></Route>
      <Route path='Careers' element={<Careers/>}></Route>
      <Route path='ContactUs' element={<ContactUs/>}></Route>
    </Routes>  
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
